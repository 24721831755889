import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import CategoriesList from "./knowledgebase-category-list"
import Img from "gatsby-image"
import Grid from "./knowledgebase-post-grid"
import Recomedations from "./knowledgebase-recomendations"
import timeToRead from "../../images/blog/time-to-read.svg"
import { formatTimeToRead } from "./knowledgebase-utils"
import HeroKnowledgeBase from "../../components/pages/shared/hero/knowledge-base"
import ContactFormComponent from "../../components/pages/shared/contact-form"
import styles from "./knowledgebase-post.module.scss"
import Breadcrumb from '../../components/controls/breadcrumbs';
// import DiscountBanner from "../../../src/knowledgebase/components/banner"

const KnowledgebasePost = (props) => {
  const { title, path, category, description } = props.data.markdownRemark.frontmatter
  const html = props.data.markdownRemark.html
  const image = props.data.markdownRemark.frontmatter.cover
    && props.data.markdownRemark.frontmatter.cover.childImageSharp.fluid
  const timeToReadMinutes = props.data.markdownRemark.timeToRead
  const crumbs = [
    { name: 'Главная', url: '/'},
    { name: 'База знаний', url: '/knowledgebase'},
    { name: category.split('|')[0], url: '/knowledgebase/category/' + category.split('/')[1]},
    { name: 'Инструкция', url: ''},
  ];
  return (
    <Layout page={path.substr(1)} headerColor={"#319DED"}>
      <HeroKnowledgeBase name={"knowledgebase"} />
      <Breadcrumb crumbs={ crumbs } arrowcolor={"blue"}/>
      {/* <DiscountBanner/> */}
      <SEO title={title} description={description} url={"https://paraplancrm.ru" + props.location.pathname}/>
      <Grid>
        <CategoriesList currentCategory={category} currentPost={path} />
        <div className={styles.wrapper}>
          <div className={styles.timeToRead}>
            <img src={timeToRead}
              alt={""} /> {formatTimeToRead(timeToReadMinutes)}
          </div>
          <h1 className={styles.title}>{title}</h1>
          {image && <Img fluid={image} />}
          <div dangerouslySetInnerHTML={{ __html: html }} />
          <Recomedations />
        </div>
      </Grid>
      <ContactFormComponent color={"#F9F9FA"} />
    </Layout>
  )
}

export const query = graphql`
query($pathSlug: String!) {
  markdownRemark(frontmatter: {path: { eq: $pathSlug }}) {
    html
    timeToRead
    frontmatter {
      title
      path
      category
      description
      cover {
        childImageSharp {
          fluid(maxWidth: 835) {
            ...GatsbyImageSharpFluid_noBase64
          }
        } 
      }
    }
  }
}`
console.log(query)
export default KnowledgebasePost