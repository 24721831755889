import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { mapToPost } from "./knowledgebase-utils"
import PostCard from "./knowledgebase-post-card"
import styles from "./knowledgebase-recomendations.module.scss"

export default () => {
  return (<StaticQuery query={query} render={
    data => {
      const { edges } = data.allMarkdownRemark
      return <div>
        <div className={styles.title}>Вам также может быть интересно</div>
        <div className={styles.recomendations}>
          {
            Array.from(edges).map(edge => {
              const post = mapToPost(edge)
              return <PostCard post={post} key={post.path} />
            })
          }
        </div>
      </div>
    }
  }/>)
}


export const query = graphql`
  query KnowledgebaseRecomendationsQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { 
        fileAbsolutePath: {regex: "/src/knowledgebase/"}
        frontmatter: { path: {ne: null} }
      }
      limit: 3
    ) {
      edges {
        node {
          frontmatter{
            cover {
              childImageSharp {
                sm: fluid(maxWidth: 470) {
                  ...GatsbyImageSharpFluid_noBase64
                }
                mob: fluid(maxWidth: 835) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            lead {
              childImageSharp {
                fixed(height: 400) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            excerpt
            path
            title
            category
          }
          timeToRead
        }
      }
    }
  }
`